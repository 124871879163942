import React, { FormEvent } from 'react';
import { trackEvent } from '@src/services/analytics';
import styles from './contact-form.module.scss';

type ContactProps = { [k: string]: unknown };

type ContactState = {
  error: boolean;
  submitted: boolean;
  loading: boolean;
  email: string;
  phone: string;
  name: string;
  message: string;
};

class ContactForm extends React.Component<ContactProps, ContactState> {
  formName = 'contact';

  state: ContactState = {
    error: false,
    submitted: false,
    loading: false,
    email: '',
    phone: '',
    name: '',
    message: '',
  };

  constructor(props: ContactProps) {
    super(props);
  }

  encode = (data: { [key: string]: string }): string => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    const { email, phone, name, message } = this.state;
    trackEvent('Contact', 'Submit');
    this.setState({ loading: true });

    setTimeout(() => {
      fetch('/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: this.encode({
          'form-name': this.formName,
          email,
          phone,
          name,
          message,
        }),
      })
        .then(this.handleSuccess)
        .catch(this.handleError);
    }, 1000);

    e.preventDefault();
  };

  handleSuccess = (): void => {
    trackEvent('Contact', 'Success');
    this.setState({ submitted: true, loading: false });
    setTimeout(() => {
      this.setState({ submitted: false, loading: false });
    }, 10000);
  };

  handleError = (): void => {
    trackEvent('Contact', 'Error');
    this.setState({ submitted: true, error: true, loading: false });
    setTimeout(() => {
      this.setState({ submitted: false, error: false, loading: false });
    }, 10000);
  };

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ email: e.target.value });
  };

  handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ phone: e.target.value });
  };

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ name: e.target.value });
  };

  handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ message: e.target.value });
  };

  render(): React.ReactNode {
    const {
      email,
      phone,
      name,
      message,
      submitted,
      loading,
      error,
    } = this.state;

    return (
      <section className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Formulaire de contact</h2>
        </div>

        <div className={styles.content}>
          <div
            className={`${styles.contact} ${
              submitted ? styles.submitted : ''
            }  ${error ? styles.submittedError : ''} ${
              loading ? styles.loading : ''
            }`}
          >
            <form
              name={this.formName}
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              className={styles.form}
              onSubmit={this.handleSubmit}
            >
              <div className={styles.infos}>
                <div className={styles.fieldEmail}>
                  <label htmlFor="email" className={styles.label}>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className={`${styles.input} ${
                        email.length > 0 ? styles.filled : ''
                      }`}
                      onChange={this.handleEmailChange}
                      value={email}
                      required
                      minLength={3}
                    />
                    <span className={styles.labelSpan}>Votre email</span>
                  </label>
                </div>
                <div className={styles.fieldPhone}>
                  <label htmlFor="phone" className={styles.label}>
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      className={`${styles.input} ${
                        phone.length > 0 ? styles.filled : ''
                      }`}
                      onChange={this.handlePhoneChange}
                      value={phone}
                      required
                      minLength={10}
                    />
                    <span className={styles.labelSpan}>Votre téléphone</span>
                  </label>
                </div>
                <div className={styles.fieldName}>
                  <label htmlFor="name" className={styles.label}>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className={`${styles.input} ${
                        name.length > 0 ? styles.filled : ''
                      }`}
                      onChange={this.handleNameChange}
                      value={name}
                      required
                      minLength={3}
                    />
                    <span className={styles.labelSpan}>Votre nom</span>
                  </label>
                </div>
              </div>

              <div className={styles.message}>
                <div className={styles.fieldMessage}>
                  <label htmlFor="message" className={styles.label}>
                    <textarea
                      id="message"
                      name="message"
                      className={`${styles.textarea} ${
                        message.length > 0 ? styles.filled : ''
                      }`}
                      onChange={this.handleMessageChange}
                      required
                      minLength={5}
                      value={message}
                    />
                    <span className={styles.labelSpan}>Votre message</span>
                  </label>
                  <div className={styles.spam}>
                    A noter que mes mails tombent parfois dans les spams. Pensez
                    à bien les vérifier et n&apos;hésitez pas à me rappeler en
                    cas de souci.
                  </div>
                </div>
              </div>
              <div className={styles.hidden}>
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value={this.formName} />
              </div>
              <div className={styles.submit}>
                <button className={styles.submitButton} type="submit">
                  Envoyer
                </button>
              </div>
            </form>
            <div className={styles.loaderContainer}>
              <div className={styles.loader}>
                <svg width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
                  <g fill="none" transform="translate(1 1)" strokeWidth="2">
                    <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </svg>
              </div>
            </div>
            <div className={styles.success}>
              <div className={styles.successTitle}>Message envoyé !</div>
              <div className={styles.successText}>
                Merci, je vous réponds au plus vite.
                <br />
                Pensez à bien vérifier vos spams.
              </div>
            </div>
            <div className={styles.error}>
              <div className={styles.errorTitle}>Une erreur est survenue !</div>
              <div className={styles.errorText}>
                Désolé, il y a eu un problème lors de l&apos;envoi du message.
                <br />
                N&apos;hésitez pas à me contacter par téléphone ou par email.
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactForm;
